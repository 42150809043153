import React from "react";
import Banner from "../../components/Banner";
import ButtonBar from "../../components/ButtonBar";
import Footer from "../../components/Footer";
import FooterMadeBy from "../../components/FooterMadeBy";
import SideMenu from "../../components/SideMenu";
import { useNavigate } from "react-router-dom";
import webService from "../../services/web.service";
import { useTranslation } from "react-i18next";
import { tcolors } from "../../config/Colors";
import { css } from "glamor";
import { STRUCTURE_NAME } from "../../config/backendConfig";

function Events({ uuid, color }) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [config, setConfig] = React.useState({});
  const [events, setEvents] = React.useState([]);
  const { t } = useTranslation("common");

  let navigate = useNavigate();

  React.useEffect(() => {
    document.title = t("titles.events", {
      structure_name: STRUCTURE_NAME,
    });
    fetchConfig();
  }, [t]);

  const fetchConfig = async () => {
    setIsLoading(true);
    webService.getConfig(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) {
          setConfig(result.data);
          fetchEvents();
        }
      },
      (error) => {}
    );
  };
  const fetchEvents = async () => {
    setIsLoading(true);
    webService.getEvents(uuid).then((result) => {
      if (result.status === 200) setEvents(result.data);
      setIsLoading(false);
    });
  };
  return (
    <>
      <Banner
        uuid={uuid}
        useVideo={false}
        color={config.color}
        logo={config.white_logo}
      />
      <SideMenu uuid={uuid} color={config.color} />

      <div className="w-full items-center flex justify-center">
        <div
          className="bg-white p-5 md:p-10 text-2xl md:text-4xl font-medium rounded-lg
        tracking-widest uppercase -mt-10 z-20 text-slate-700 shadow-lg"
        >
          {t("events.title")}
        </div>
      </div>

      <div className="max-w-7xl mx-auto flex flex-col gap-20 py-16">
        {events?.map((ev, index) => (
          <div
            className="flex flex-col lg:flex-row items-start gap-10  p-5
           transition rounded-lg"
          >
            <img
              src={ev.image}
              className="w-[200px] h-[200px] object-cover rounded-md"
              alt={"event-" + index}
            />
            <div className="flex flex-col">
              <h2
                className="text-2xl uppercase font-semibold"
                {...css({
                  color: tcolors[config.color + "-600"],
                  ":hover": {
                    color: tcolors[config.color + "-800"],
                  },
                })}
              >
                {ev.title_obj.text}
              </h2>

              <p className="mt-5 text-gray-600">{ev.description_obj.text}</p>
              <span className="mt-5 text-gray-300 font-semibold">
                Date: {ev.date}
              </span>
            </div>
          </div>
        ))}
      </div>

      <Footer uuid={uuid} color={config.color} logo={config.white_logo} />
      <FooterMadeBy />
      <ButtonBar color={config.color} />
    </>
  );
}

export default Events;

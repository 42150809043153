import React from "react";
import { ImageCDN } from "./ImageCDN";
import { tcolors } from "../config/Colors";
import { css } from "glamor";
import { useTranslation } from "react-i18next";
import webService from "../services/web.service";
import LoadingIndicator from "./LoadingIndicator";

function Footer({ uuid, color, logo }) {
  const { t } = useTranslation("common");
  const [isLoading, setIsLoading] = React.useState(false);
  const [footerInfo, setFooterInfo] = React.useState({
    reg_business_name: "",
    reg_address: "",
    reg_zip_code: "",
    reg_country: "",
    reg_province: "",
    reg_municipality: "",
    reg_phone: "",
    reg_email: "",
  });
  const [footerLinks, setFooterLinks] = React.useState([]);

  React.useEffect(() => {
    if (uuid) fetchFooterInfo();
  }, [t]);

  const fetchFooterInfo = async () => {
    setIsLoading(true);
    webService.getFooterInfo(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setFooterInfo(result.data[0]);
        fetchFooterLinks();
      },
      (error) => {}
    );
  };

  const fetchFooterLinks = async () => {
    webService.getFooterLinks(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setFooterLinks(result.data);
        setIsLoading(false);
      },
      (error) => {}
    );
  };

  return (
    <>
      {isLoading ? (
        <div className="w-full h-full flex items-center justify-center py-4 mx-auto">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          <div
            className={`grid grid-cols-1 md:grid-cols-4 space-y-10 
            px-32 py-14 pt-7  text-gray-600`}
            {...css({
              backgroundColor: "#1D1D1D",
            })}
          >
            <div className="space-y-4 text-xs text-gray-800">
              <img
                className="w-1/2 h-full object-contain"
                alt={"website white logo"}
                src={logo}
              />
            </div>
            <div className="space-y-4 text-sm text-white">
              <h5 className="font-bold text-white !text-lg">
                {footerInfo.reg_business_name}
              </h5>
              <p>{footerInfo.reg_address}</p>
              <p>
                {footerInfo.reg_zip_code} ({footerInfo.reg_province})
              </p>
              <p>
                {footerInfo.reg_municipality}, {footerInfo.reg_country}
              </p>
            </div>
            <div className="space-y-4 text-sm text-white">
              <h5 className="font-bold text-white !text-lg">
                {t("footer.title_label_1")}
              </h5>
              <p>{footerInfo.reg_phone}</p>
              <p>{footerInfo.reg_email}</p>
            </div>
            <div className="space-y-4 text-sm text-white">
              <h5 className="font-bold text-white !text-lg">
                {t("footer.title_label_2")}
              </h5>
              <div className="flex flex-col gap-4"></div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Footer;

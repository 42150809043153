import { PhoneOutlined, ShopOutlined, MailOutlined } from "@ant-design/icons";
import { css } from "glamor";
import React from "react";
import { tcolors } from "../config/Colors";
import webService from "../services/web.service";
import { useTranslation } from "react-i18next";

function ButtonBar({ uuid, color }) {
  const { t } = useTranslation("common");

  const [hasPreventivo, setHasPreventivo] = React.useState(false);
  const [footerInfo, setFooterInfo] = React.useState({
    reg_phone: "+393386838273",
    reg_email: "welcomehomeperugia@gmail.com",
  });

  React.useEffect(() => {
    if (uuid) fetchFooterInfo();

    setButtons([
      {
        id: 1,
        text: t("button_bar.button1"),
        icon: <ShopOutlined className="text-white text-2xl" />,
        url: "https://www.idabooking.eu/Home/search_base?uuid=" + uuid,
      },
      {
        id: 2,
        text: t("button_bar.button2"),
        icon: <PhoneOutlined className="text-white text-2xl" />,
        url: "tel:" + footerInfo.reg_phone,
      },
      {
        id: 3,
        text: t("button_bar.button3"),
        icon: <MailOutlined className="text-white text-2xl" />,
        url: "mailto:" + footerInfo.reg_email,
      },
    ]);
  }, [t]);

  const fetchFooterInfo = async () => {
    webService.getButtonBar(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setFooterInfo(result.data[0]);
      },
      (error) => {}
    );
  };

  React.useEffect(() => {
    if (uuid) checkPreventivo();
  }, []);

  const checkPreventivo = async () => {
    webService.hasPreventivo(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setHasPreventivo(result.data);
      },
      (error) => {}
    );
  };

  const [buttons, setButtons] = React.useState([
    {
      id: 1,
      text: t("button_bar.button1"),
      icon: <ShopOutlined className="text-white text-2xl" />,
      url: "https://www.idabooking.eu/Home/search_base?uuid=" + uuid,
    },
    {
      id: 2,
      text: t("button_bar.button2"),
      icon: <PhoneOutlined className="text-white text-2xl" />,
      url: "tel:" + footerInfo.reg_phone,
    },
    {
      id: 3,
      text: t("button_bar.button3"),
      icon: <MailOutlined className="text-white text-2xl" />,
      url: "mailto:" + footerInfo.reg_email,
    },
  ]);
  const CustomButton = (params) => (
    <a
      className="h-full w-full cursor-pointer transition 
      flex-col gap-1"
      {...css({
        backgroundColor: tcolors[color + "-500"],
        ":hover": { backgroundColor: tcolors[color + "-600"] },
      })}
      href={params.button.url}
    >
      <div className="h-full flex flex-col items-center justify-center hover:scale-90 duration-150">
        {params.button.icon}
        <span className="text-white capitalize">{params.button.text}</span>
      </div>
    </a>
  );
  return (
    <div
      className={`lg:hidden 
     w-full bottom-0 h-36 sticky z-30 flex flex-col ${
       hasPreventivo && "rounded-t-md"
     }`}
      {...css({
        backgroundColor: tcolors[color + "-500"],
      })}
    >
      {hasPreventivo && (
        <div
          className="transition p-3 w-full text-white 
           flex flex-row items-center justify-center gap-3 
           rounded-t-md
           cursor-pointer"
          {...css({
            backgroundColor: tcolors[color + "-400"],
            ":hover": { backgroundColor: tcolors[color + "-600"] },
          })}
          onClick={() =>
            window.open(
              "https://www.idabooking.eu/public/preventivo/request/" + uuid,
              "_blank"
            )
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-7 h-7 "
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
            />
          </svg>

          <div className="text-center uppercase font-semibold">
            {t("booking_engine.getQuoteButton")}
          </div>
        </div>
      )}

      <div className="flex flex-row items-center h-full">
        {buttons?.map((button, index) => (
          <CustomButton button={button} key={"custom-button-" + index} />
        ))}
      </div>
    </div>
  );
}

export default ButtonBar;

import {
  ArrowRightOutlined,
  FullscreenOutlined,
  WifiOutlined,
} from "@ant-design/icons";
import { Button, Divider } from "antd";
import React from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { useNavigate } from "react-router-dom";
import { ImageCDN } from "./ImageCDN";
import Separator from "./Separator";
import { css } from "glamor";
import { tcolors } from "../config/Colors";
import webService from "../services/web.service";
import LoadingIndicator from "./LoadingIndicator";
import { useTranslation } from "react-i18next";
import { faMale } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ServiceRoom from "../components/ServiceRoom";
import ScrollAnimation from "react-animate-on-scroll";

function RoomsPreview({ uuid, color }) {
  const [rooms, setRooms] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { t } = useTranslation("common");

  React.useEffect(() => {
    if (uuid) fetchRooms();
  }, [t]);

  const fetchRooms = async () => {
    setIsLoading(true);
    webService.getRooms(uuid).then(
      (result) => {
        const status = result.status;
        if (status === 200) setRooms(result.data);
        setIsLoading(false);
      },
      (error) => {}
    );
  };

  const navigate = useNavigate();
  const SingleRoom = ({ room }) => (
    <ScrollAnimation animateIn="fadeIn">
      <div
        className="relative h-[28rem] w-80 sm:w-96 flex flex-col 
      rounded-xl shadow-lg shadow-gray-300"
      >
        <img
          src={ImageCDN(room.category.image, "600x400")}
          alt="image-room"
          className="object-cover h-40 rounded-t-xl"
          loading="lazy"
        />
        <div className="flex flex-col sm:flex-row items-center gap-2">
          <h3 className="text-gray-700 font-semibold py-3 px-2 text-2xl w-full sm:w-2/3">
            <LinesEllipsis
              text={room.name}
              maxLine="2"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </h3>
          <div className="w-full sm:w-1/3">
            <div
              className="bg-white rounded-xl shadow-md p-3
            absolute top-10 sm:top-32 right-6 w-36 opacity-90 h-24 "
            >
              <p className="tracking-wide font-semibold">
                {t("rooms_preview.starting_from")}
              </p>
              <h1 className=" text-green-500 text-2xl md:text-3xl font-semibold">
                &euro; {parseFloat(room.price).toFixed(2)}
              </h1>
            </div>
          </div>
        </div>

        <div className="flex flex-row mt-4">
          <Divider style={{ margin: "0" }}>
            <div className="flex flex-row items-center">
              <div className="flex flex-row items-center gap-1">
                {[...Array(room.occupancy)].map((e, i) => (
                  <FontAwesomeIcon
                    icon={faMale}
                    className=" text-lg"
                    {...css({
                      color: tcolors[color + "-600"],
                    })}
                  />
                ))}
              </div>
              {room.services.length > 0 && (
                <>
                  <Divider type="vertical" className="bg-gray-300" />

                  <div className="flex flex-row items-center gap-3">
                    {room.services?.map(
                      (service, index) =>
                        index < 5 && (
                          <div className="flex flex-row items-center">
                            <ServiceRoom
                              index={service}
                              color={color}
                              onlyIcon={true}
                            />
                          </div>
                        )
                    )}
                  </div>
                </>
              )}
            </div>
          </Divider>
        </div>

        <div className="p-3 text-gray-500">
          <LinesEllipsis
            text={
              room.category.descr
                ? room.category.descr.replace(/(<([^>]+)>)/gi, "")
                : ""
            }
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </div>

        <div className="mt-auto p-3 w-full">
          <div
            className="w-full rounded-lg font-normal flex items-center px-4 cursor-pointer"
            onClick={() => navigate("/room/" + room.id_room)}
            {...css({
              height: "40px",
              fontSize: "18px",
              backgroundColor: tcolors[color + "-500"],
              color: "#FFF",
              ":hover": { backgroundColor: tcolors[color + "-600"] },
            })}
          >
            <div className=" flex flex-row items-center justify-start w-full gap-2">
              <span className="uppercase tracking-wider font-medium">
                {t("rooms_preview.action")}
              </span>
              <ArrowRightOutlined className="ml-auto" />
            </div>
          </div>
        </div>
      </div>
    </ScrollAnimation>
  );

  return (
    <div className="flex flex-col items-center mx-auto mt-20 mb-20 w-full">
      <h1 className="text-4xl font-semibold tracking-wide sm:tracking-widest text-gray-600 uppercase">
        {t("rooms_preview.title")}
      </h1>
      <Separator />
      <div className="overflow-x-scroll max-w-7xl w-full flex p-3">
        <section className="flex space-x-6 py-5 mx-auto">
          <>
            {isLoading ? (
              <div className="w-full h-full flex items-center justify-center py-4 mx-auto">
                <LoadingIndicator />
              </div>
            ) : (
              <>
                {rooms?.map((room, index) => (
                  <SingleRoom room={room} key={"room-" + index} />
                ))}
              </>
            )}
          </>
        </section>
      </div>
    </div>
  );
}

export default RoomsPreview;
